const IdahoZipCodes = [
  83201, 83202, 83203, 83204, 83205, 83206, 83209, 83210, 83214, 83215, 83218, 83221, 83234, 83236, 83245, 83246, 83250,
  83256, 83262, 83274, 83277, 83281, 83301, 83302, 83303, 83313, 83316, 83320, 83321, 83324, 83325, 83328, 83333, 83334,
  83335, 83338, 83340, 83341, 83344, 83348, 83349, 83352, 83353, 83354, 83401, 83402, 83403, 83404, 83405, 83406, 83415,
  83423, 83427, 83428, 83440, 83441, 83446, 83448, 83449, 83454, 83460, 83523, 83543, 83555, 83605, 83606, 83607, 83610,
  83611, 83615, 83616, 83619, 83626, 83630, 83634, 83635, 83638, 83641, 83642, 83644, 83645, 83646, 83651, 83652, 83653,
  83655, 83656, 83660, 83661, 83669, 83672, 83676, 83677, 83680, 83686, 83687, 83701, 83702, 83703, 83704, 83705, 83706,
  83707, 83708, 83709, 83711, 83712, 83713, 83714, 83715, 83716, 83717, 83719, 83720, 83721, 83722, 83724, 83725, 83726,
  83727, 83728, 83729, 83730, 83731, 83732, 83733, 83735, 83756, 83757, 83799, 83802, 83804, 83808, 83809, 83811, 83812,
  83813, 83821, 83822, 83825, 83836, 83837, 83839, 83840, 83841, 83846, 83848, 83849, 83850, 83852, 83856, 83860, 83864,
  83865, 83867, 83868, 83873, 83874
]; // 158
export default IdahoZipCodes;
