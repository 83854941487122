const WashingtonZipCodes = [
  98001, 98002, 98003, 98004, 98005, 98006, 98007, 98008, 98009, 98010, 98011, 98012, 98013, 98014, 98015, 98019, 98020,
  98021, 98022, 98023, 98024, 98025, 98026, 98027, 98028, 98029, 98030, 98031, 98032, 98033, 98034, 98035, 98036, 98037,
  98038, 98039, 98040, 98041, 98042, 98043, 98045, 98046, 98047, 98050, 98051, 98052, 98053, 98054, 98055, 98056, 98057,
  98058, 98059, 98061, 98062, 98063, 98064, 98065, 98068, 98070, 98071, 98072, 98073, 98074, 98075, 98077, 98082, 98083,
  98087, 98089, 98092, 98093, 98101, 98102, 98103, 98104, 98105, 98106, 98107, 98108, 98109, 98110, 98111, 98112, 98113,
  98114, 98115, 98116, 98117, 98118, 98119, 98121, 98122, 98124, 98125, 98126, 98127, 98129, 98131, 98132, 98133, 98134,
  98136, 98138, 98139, 98141, 98144, 98145, 98146, 98148, 98151, 98154, 98155, 98158, 98160, 98161, 98164, 98165, 98166,
  98168, 98170, 98171, 98174, 98175, 98177, 98178, 98181, 98184, 98185, 98188, 98189, 98190, 98191, 98194, 98195, 98198,
  98199, 98201, 98203, 98204, 98205, 98206, 98207, 98208, 98213, 98220, 98221, 98222, 98223, 98224, 98225, 98226, 98227,
  98228, 98229, 98230, 98231, 98232, 98233, 98235, 98237, 98238, 98240, 98241, 98243, 98244, 98245, 98247, 98248, 98250,
  98251, 98252, 98255, 98256, 98257, 98258, 98259, 98261, 98262, 98263, 98264, 98266, 98267, 98270, 98271, 98272, 98273,
  98274, 98275, 98276, 98279, 98280, 98281, 98283, 98284, 98286, 98287, 98288, 98290, 98291, 98292, 98293, 98294, 98295,
  98296, 98297, 98303, 98304, 98305, 98310, 98311, 98312, 98314, 98315, 98321, 98322, 98323, 98324, 98326, 98327, 98328,
  98329, 98330, 98331, 98332, 98333, 98335, 98336, 98337, 98338, 98340, 98342, 98343, 98344, 98345, 98346, 98348, 98349,
  98350, 98351, 98352, 98353, 98354, 98355, 98356, 98357, 98359, 98360, 98361, 98362, 98363, 98364, 98366, 98367, 98370,
  98371, 98372, 98373, 98374, 98375, 98377, 98378, 98380, 98381, 98382, 98383, 98384, 98385, 98386, 98387, 98388, 98390,
  98391, 98392, 98393, 98394, 98395, 98396, 98397, 98398, 98401, 98402, 98403, 98404, 98405, 98406, 98407, 98408, 98409,
  98411, 98412, 98413, 98415, 98416, 98417, 98418, 98419, 98421, 98422, 98424, 98430, 98431, 98433, 98438, 98439, 98442,
  98443, 98444, 98445, 98446, 98447, 98448, 98450, 98455, 98460, 98464, 98465, 98466, 98467, 98471, 98477, 98481, 98490,
  98492, 98493, 98496, 98497, 98498, 98499, 98501, 98502, 98503, 98504, 98505, 98506, 98507, 98508, 98509, 98511, 98512,
  98513, 98516, 98522, 98530, 98531, 98532, 98533, 98538, 98539, 98540, 98542, 98544, 98556, 98558, 98564, 98565, 98570,
  98572, 98576, 98579, 98580, 98582, 98585, 98589, 98591, 98593, 98596, 98597, 98599, 98601, 98604, 98606, 98607, 98622,
  98629, 98642, 98660, 98661, 98662, 98663, 98664, 98665, 98666, 98667, 98668, 98671, 98675, 98682, 98683, 98684, 98685,
  98686, 98687, 98801, 98807, 98811, 98812, 98814, 98815, 98816, 98817, 98819, 98821, 98822, 98823, 98824, 98826, 98827,
  98828, 98829, 98831, 98832, 98833, 98834, 98836, 98837, 98840, 98841, 98844, 98846, 98847, 98848, 98849, 98851, 98852,
  98853, 98855, 98856, 98857, 98859, 98860, 98862, 98901, 98902, 98903, 98904, 98907, 98908, 98909, 98920, 98921, 98922,
  98923, 98925, 98926, 98929, 98930, 98932, 98933, 98934, 98935, 98936, 98937, 98938, 98939, 98940, 98941, 98942, 98943,
  98944, 98946, 98947, 98948, 98950, 98951, 98952, 98953, 99001, 99003, 99004, 99005, 99006, 99008, 99009, 99011, 99012,
  99013, 99014, 99016, 99017, 99018, 99019, 99020, 99021, 99022, 99023, 99025, 99026, 99027, 99029, 99030, 99031, 99032,
  99033, 99034, 99036, 99037, 99039, 99040, 99101, 99102, 99103, 99104, 99107, 99109, 99110, 99111, 99113, 99114, 99115,
  99116, 99117, 99118, 99119, 99121, 99122, 99123, 99124, 99125, 99126, 99128, 99129, 99130, 99131, 99133, 99134, 99135,
  99136, 99137, 99138, 99139, 99140, 99141, 99143, 99144, 99146, 99147, 99148, 99149, 99150, 99151, 99152, 99153, 99154,
  99155, 99156, 99157, 99158, 99159, 99160, 99161, 99163, 99164, 99165, 99166, 99167, 99170, 99171, 99173, 99174, 99176,
  99179, 99180, 99181, 99185, 99201, 99202, 99203, 99204, 99205, 99206, 99207, 99208, 99209, 99210, 99211, 99212, 99213,
  99214, 99215, 99216, 99217, 99218, 99219, 99220, 99223, 99224, 99228, 99251, 99252, 99256, 99258, 99260, 99299, 99320,
  99321, 99323, 99324, 99329, 99333, 99336, 99337, 99338, 99345, 99346, 99348, 99349, 99350, 99352, 99353, 99354, 99357,
  99360, 99361, 99362, 99363, 99401, 99402, 99403
]; // 619
export default WashingtonZipCodes;
