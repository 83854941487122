const NevadaZipCodes = [
  89001, 89002, 89004, 89005, 89006, 89007, 89008, 89009, 89011, 89012, 89014, 89015, 89016, 89017, 89018, 89019, 89021,
  89024, 89025, 89026, 89027, 89028, 89029, 89030, 89031, 89032, 89033, 89034, 89036, 89037, 89039, 89040, 89042, 89043,
  89044, 89046, 89052, 89053, 89054, 89067, 89070, 89074, 89077, 89081, 89084, 89085, 89086, 89087, 89101, 89102, 89103,
  89104, 89105, 89106, 89107, 89108, 89109, 89110, 89111, 89112, 89113, 89114, 89115, 89116, 89117, 89118, 89119, 89120,
  89121, 89122, 89123, 89124, 89125, 89126, 89127, 89128, 89129, 89130, 89131, 89132, 89133, 89134, 89135, 89136, 89137,
  89138, 89139, 89140, 89141, 89142, 89143, 89144, 89145, 89146, 89147, 89148, 89149, 89150, 89151, 89152, 89153, 89154,
  89155, 89156, 89157, 89158, 89159, 89160, 89161, 89162, 89163, 89164, 89165, 89166, 89169, 89170, 89173, 89177, 89178,
  89179, 89180, 89183, 89185, 89191, 89193, 89195, 89199, 89301, 89311, 89315, 89317, 89318, 89319, 89403, 89406, 89407,
  89408, 89428, 89429, 89430, 89447, 89496, 89801, 89802, 89803, 89815, 89822, 89823, 89824, 89825, 89826, 89828, 89830,
  89831, 89832, 89833, 89834, 89835, 89883
]; // 161 - 2 = 159
export default NevadaZipCodes;
