// NOTE: I manually moved 88901, 88905 from NevadaZipCodes to here so that they will work (cannot have the same prefix "88" in 2 states)
const NewMexicoZipCodes = [
  87001, 87004, 87006, 87008, 87010, 87011, 87012, 87013, 87015, 87017, 87018, 87022, 87024, 87025, 87027, 87028, 87029,
  87037, 87041, 87043, 87044, 87045, 87046, 87047, 87048, 87052, 87053, 87056, 87059, 87062, 87064, 87072, 87083, 87101,
  87102, 87103, 87104, 87105, 87106, 87107, 87108, 87109, 87110, 87111, 87112, 87113, 87114, 87115, 87116, 87117, 87119,
  87120, 87121, 87122, 87123, 87124, 87125, 87131, 87144, 87151, 87153, 87154, 87158, 87165, 87174, 87176, 87181, 87184,
  87185, 87187, 87190, 87191, 87192, 87193, 87194, 87195, 87196, 87197, 87198, 87199, 87301, 87302, 87305, 87310, 87311,
  87312, 87313, 87316, 87317, 87319, 87320, 87322, 87323, 87325, 87326, 87327, 87328, 87347, 87364, 87365, 87375, 87401,
  87402, 87410, 87412, 87413, 87415, 87416, 87417, 87418, 87419, 87420, 87421, 87455, 87461, 87499, 87501, 87502, 87503,
  87504, 87505, 87506, 87507, 87508, 87509, 87510, 87511, 87515, 87516, 87518, 87520, 87522, 87523, 87527, 87528, 87530,
  87531, 87532, 87533, 87535, 87537, 87539, 87540, 87548, 87549, 87551, 87554, 87566, 87567, 87574, 87575, 87578, 87581,
  87582, 87592, 87594, 87801, 87823, 87825, 87828, 87831, 87832, 87936, 87937, 87940, 87941, 88001, 88002, 88003, 88004,
  88005, 88006, 88007, 88008, 88011, 88012, 88013, 88021, 88022, 88023, 88024, 88025, 88026, 88027, 88028, 88032, 88033,
  88034, 88036, 88038, 88040, 88041, 88043, 88044, 88046, 88047, 88048, 88049, 88051, 88052, 88053, 88054, 88055, 88058,
  88061, 88062, 88063, 88065, 88072, 88081, 88101, 88102, 88103, 88112, 88114, 88120, 88124, 88133, 88135, 88201, 88202,
  88203, 88213, 88230, 88231, 88232, 88240, 88241, 88242, 88244, 88252, 88253, 88260, 88262, 88264, 88265, 88267, 88301,
  88310, 88311, 88312, 88314, 88316, 88317, 88318, 88323, 88324, 88325, 88330, 88336, 88337, 88338, 88339, 88340, 88341,
  88342, 88343, 88344, 88345, 88346, 88347, 88348, 88349, 88350, 88351, 88352, 88354, 88355, 88410, 88414, 88415, 88418,
  88419, 88422, 88424, 88436
]; // 276 + 2 = 278
export default NewMexicoZipCodes;
