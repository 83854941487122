/* eslint-disable no-console */
// Modules
import React, { useState, useRef, useEffect } from "react";

// React Bootstrap
import Modal from "react-bootstrap/Modal";

// Styles
import "./zip-code-filter.bootstrap.scss";

// Components
import { useLanguageContext } from "../../contexts/language-context";
import NotificationAlert from "../notifications/notification-alert";
import Icon from "../custom-widgets/icon";

// Helper functions
import isZipCodeEligible from "../../helpers/isZipCodeEligible";

const ZipCodeFilter = (props) => {
  const isSpanish = useLanguageContext();

  // Error messages if applicable.
  const [ineligibleMessage, setIneligibleMessage] = useState(null);
  const [validationErrorMessage, setValidationErrorMessage] = useState(null);

  // Get references to the search text inputs so that we can access their values
  const zipCodeInputRef = useRef();

  // simple boolean flag if the search has been performed or not
  const [hasPerformedCheck, setHasPerformedCheck] = useState(false);

  const [isEligible, setIsEligible] = useState(false);

  // isEligible change handler
  useEffect(() => {
    if (hasPerformedCheck) {
      if (isEligible && typeof window !== "undefined") {
        // console.log("Zip code is eligible, redirecting...");
        setIneligibleMessage("");
        window.location.href = props.eligibleUrl;
      } else {
        // console.warn("Zip code is NOT eligible!");
        setIneligibleMessage(
          isSpanish
            ? `¡Lo sentimos! Se encuentra fuera de nuestra área de préstamo y no es elegible para aplicar.`
            : `We're sorry! You are outside of our lending area and ineligible to apply.`
        );
        // TODO: I don't believe that utag is in scope here or that this is needed here.
        // utag.link({
        //   tealium_event: "gatsby-error",
        //   error_type: "form-error",
        //   error_message: "The zip code entered is outside of our lending area and is ineligible to apply."
        // });
      }
    }
  }, [isEligible, hasPerformedCheck]);

  // Clear errors when the modal is closed or reopened
  useEffect(() => {
    setIneligibleMessage(null);
    setValidationErrorMessage(null);
    setHasPerformedCheck(false);
    setIsEligible(false);
  }, [props.showModal]);

  // Check zip code
  const checkZipCode = (zipText) => {
    // zip code input validation
    if (zipText !== null && zipText.toString().match(/^[0-9]{5}$/)) {
      setValidationErrorMessage(null);
      // Check zip code for eligibility
      setIsEligible(isZipCodeEligible(zipText));
      setHasPerformedCheck(true);
    } else {
      // invalid zip code
      setIneligibleMessage(null);
      setValidationErrorMessage(
        isSpanish ? "Por favor ingrese un código postal válido." : "Please enter a valid zip code."
      );
      setHasPerformedCheck(false);
    }
  };

  return (
    <Modal id="zip-code-filter-modal" show={props.showModal} onHide={props.handleCloseModal} size="md">
      <Modal.Header closeButton>
        <Modal.Title className="h3-font-size font-weight-bold text-success">
          {isSpanish ? "Empecemos" : "Let's Get Started"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4>{isSpanish ? "Por favor ingrese su código postal." : "Please enter your zip code."}</h4>
        <div className={`mb-2 ${validationErrorMessage ? "text-red" : "text-black"}`}>
          {isSpanish ? "Código Postal" : "ZIP Code"}
        </div>
        <div className="input-group w-50">
          {/* Search Text Input */}
          <input
            ref={zipCodeInputRef}
            type="number"
            name="zipCodeText"
            id="zipCodeText"
            placeholder=""
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                checkZipCode(e.target.value);
              }
              // Enforce maxLength
              if (e.target.value.length > 5) {
                e.target.value = e.target.value.slice(0, 5);
              }
            }}
            style={{ borderRadius: "6px" }}
            className={`form-control ${
              validationErrorMessage ? "border-danger border-width-2 bg-red-10 error-state" : ""
            }`}
          />
        </div>
        {/* Error Messages */}
        {validationErrorMessage && (
          <div className="d-flex mt-3 text-danger" id="zip-code-validation-notification-alert">
            <Icon name="exclamation-triangle" lib="fas" class="mt-1 mr-3 fa-w-18" />
            {validationErrorMessage}
          </div>
        )}
        {ineligibleMessage && (
          <NotificationAlert
            id="zip-code-ineligible-notification-alert"
            bodyText={ineligibleMessage}
            type="danger"
            class="mt-3"
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="btn btn-link no-min-width" id="cancel-modal-cta" onClick={props.handleCloseModal}>
          {isSpanish ? "Cancelar" : "Cancel"}
        </div>
        {/* "Submit" Button */}
        <button
          type="button"
          className="btn btn-primary no-min-width"
          id="zipCodeSubmitButton"
          onClick={() => checkZipCode(zipCodeInputRef.current.value)}
        >
          {isSpanish ? "Enviar" : "Submit"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ZipCodeFilter;

ZipCodeFilter.defaultProps = {
  eligibleUrl: "/personal-banking/credit-cards", // default fallback
  handleCloseModal: "", // close modal handler callback
  showModal: "" // open modal value
};
