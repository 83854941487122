const OregonZipCodes = [
  97001, 97002, 97003, 97004, 97005, 97006, 97007, 97008, 97009, 97010, 97011, 97013, 97014, 97015, 97017, 97019, 97020,
  97021, 97022, 97023, 97024, 97026, 97027, 97028, 97030, 97031, 97032, 97034, 97035, 97036, 97037, 97038, 97040, 97041,
  97042, 97044, 97045, 97049, 97055, 97057, 97058, 97060, 97062, 97063, 97067, 97068, 97070, 97071, 97075, 97076, 97077,
  97078, 97080, 97086, 97089, 97106, 97109, 97113, 97116, 97117, 97119, 97123, 97124, 97125, 97129, 97133, 97137, 97140,
  97144, 97201, 97202, 97203, 97204, 97205, 97206, 97207, 97208, 97209, 97210, 97211, 97212, 97213, 97214, 97215, 97216,
  97217, 97218, 97219, 97220, 97221, 97222, 97223, 97224, 97225, 97227, 97228, 97229, 97230, 97231, 97232, 97233, 97236,
  97238, 97239, 97240, 97242, 97251, 97252, 97253, 97254, 97255, 97256, 97258, 97259, 97266, 97267, 97268, 97269, 97271,
  97272, 97280, 97281, 97282, 97283, 97286, 97290, 97291, 97292, 97293, 97294, 97296, 97298, 97299, 97301, 97302, 97303,
  97304, 97305, 97306, 97307, 97308, 97309, 97310, 97311, 97312, 97313, 97314, 97317, 97321, 97322, 97324, 97325, 97326,
  97327, 97329, 97330, 97331, 97333, 97335, 97336, 97338, 97339, 97341, 97342, 97343, 97344, 97345, 97346, 97347, 97348,
  97350, 97351, 97352, 97355, 97357, 97358, 97360, 97361, 97362, 97364, 97365, 97366, 97367, 97368, 97369, 97370, 97371,
  97372, 97373, 97374, 97375, 97376, 97377, 97380, 97381, 97383, 97384, 97385, 97386, 97388, 97389, 97390, 97391, 97392,
  97394, 97401, 97402, 97403, 97404, 97405, 97406, 97408, 97409, 97412, 97413, 97415, 97419, 97424, 97425, 97426, 97427,
  97430, 97431, 97434, 97437, 97438, 97439, 97440, 97444, 97446, 97448, 97450, 97451, 97452, 97453, 97454, 97455, 97456,
  97461, 97463, 97464, 97465, 97472, 97475, 97476, 97477, 97478, 97480, 97482, 97487, 97488, 97489, 97490, 97491, 97492,
  97493, 97497, 97498, 97501, 97502, 97503, 97504, 97520, 97522, 97523, 97524, 97525, 97526, 97527, 97528, 97530, 97531,
  97532, 97533, 97534, 97535, 97536, 97537, 97538, 97539, 97540, 97541, 97543, 97544, 97601, 97602, 97603, 97604, 97620,
  97621, 97622, 97623, 97624, 97625, 97626, 97627, 97630, 97632, 97633, 97634, 97636, 97637, 97638, 97639, 97640, 97641,
  97701, 97702, 97703, 97707, 97708, 97709, 97712, 97731, 97733, 97735, 97737, 97739, 97751, 97752, 97753, 97754, 97756,
  97759, 97801, 97810, 97813, 97817, 97820, 97824, 97825, 97826, 97827, 97835, 97838, 97841, 97845, 97848, 97850, 97856,
  97859, 97862, 97864, 97865, 97867, 97868, 97869, 97873, 97875, 97876, 97880, 97882, 97883, 97886, 97901, 97902, 97903,
  97906, 97908, 97909, 97910, 97911, 97913, 97914, 97917, 97918, 97920
]; // 367
export default OregonZipCodes;
