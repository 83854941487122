/* eslint-disable no-console */
import EligibleZipCodes from "../data/zip-codes/eligible-zip-codes";

function isZipCodeEligible(zipCode) {
  let isEligible = false;
  if (zipCode !== null) {
    const zip = zipCode.toString();
    let prefixFound = false;
    if (zip.match(/^[0-9]{5}$/)) {
      let prefix = parseInt(zip.substring(0, 2));
      for (const chunk of EligibleZipCodes) {
        if (chunk.prefixes.includes(prefix)) {
          prefixFound = true;
          isEligible = chunk.zipCodes.includes(parseInt(zip));
          break;
        }
      }
      if (!prefixFound) {
        isEligible = false;
      }
    }
  }
  return isEligible;
}
export default isZipCodeEligible;
