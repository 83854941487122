import ArizonaZipCodes from "./az-zip-codes";
import CaliforniaZipCodes from "./ca-zip-codes";
import IdahoZipCodes from "./id-zip-codes";
import NewMexicoZipCodes from "./nm-zip-codes";
import NevadaZipCodes from "./nv-zip-codes";
import OregonZipCodes from "./or-zip-codes";
import TexasZipCodes from "./tx-zip-codes";
import UtahZipCodes from "./ut-zip-codes";
import WashingtonZipCodes from "./wa-zip-codes";

// const EligiblePrefixes = [73, 75, 77, 78, 79, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 97, 98, 99];
const EligibleZipCodes = [
  {
    state: "TX",
    prefixes: [73, 75, 77, 78, 79],
    zipCodes: [...TexasZipCodes]
  },
  {
    state: "ID",
    prefixes: [83],
    zipCodes: [...IdahoZipCodes]
  },
  {
    state: "UT",
    prefixes: [84],
    zipCodes: [...UtahZipCodes]
  },
  {
    state: "AZ",
    prefixes: [85, 86],
    zipCodes: [...ArizonaZipCodes]
  },
  {
    state: "NM",
    prefixes: [87, 88],
    zipCodes: [...NewMexicoZipCodes]
  },
  // NOTE: I manually moved 88901, 88905 from NevadaZipCodes to NewMexicoZipCodes so that they will work (cannot have the same prefix "88" in 2 states)
  {
    state: "NV",
    prefixes: [89],
    zipCodes: [...NevadaZipCodes]
  },
  {
    state: "CA",
    prefixes: [90, 91, 92, 93, 94, 95],
    zipCodes: [...CaliforniaZipCodes]
  },
  {
    state: "OR",
    prefixes: [97],
    zipCodes: [...OregonZipCodes]
  },

  {
    state: "WA",
    prefixes: [98, 99],
    zipCodes: [...WashingtonZipCodes]
  }
];
export default EligibleZipCodes;
