const UtahZipCodes = [
  84006, 84009, 84010, 84011, 84014, 84015, 84016, 84020, 84025, 84037, 84040, 84041, 84044, 84047, 84054, 84056, 84065,
  84070, 84075, 84081, 84084, 84087, 84088, 84089, 84090, 84091, 84092, 84093, 84094, 84095, 84096, 84101, 84102, 84103,
  84104, 84105, 84106, 84107, 84108, 84109, 84110, 84111, 84112, 84113, 84114, 84115, 84116, 84117, 84118, 84119, 84120,
  84121, 84122, 84123, 84124, 84125, 84126, 84127, 84128, 84129, 84130, 84131, 84132, 84133, 84134, 84136, 84138, 84139,
  84141, 84143, 84144, 84145, 84147, 84148, 84150, 84151, 84152, 84157, 84158, 84165, 84170, 84171, 84180, 84184, 84189,
  84190, 84199, 84304, 84305, 84308, 84318, 84319, 84320, 84321, 84322, 84323, 84325, 84326, 84327, 84328, 84332, 84333,
  84335, 84338, 84339, 84341, 84501, 84510, 84511, 84512, 84520, 84526, 84529, 84530, 84531, 84534, 84535, 84536, 84539,
  84542, 84722, 84725, 84733, 84737, 84738, 84745, 84746, 84757, 84763, 84765, 84767, 84770, 84771, 84774, 84779, 84780,
  84781, 84782, 84783, 84784, 84790, 84791
]; // 142
export default UtahZipCodes;
