const CaliforniaZipCodes = [
  90001, 90002, 90003, 90004, 90005, 90006, 90007, 90008, 90009, 90010, 90011, 90012, 90013, 90014, 90015, 90016, 90017,
  90018, 90019, 90020, 90021, 90022, 90023, 90024, 90025, 90026, 90027, 90028, 90029, 90030, 90031, 90032, 90033, 90034,
  90035, 90036, 90037, 90038, 90039, 90040, 90041, 90042, 90043, 90044, 90045, 90046, 90047, 90048, 90049, 90050, 90051,
  90052, 90053, 90054, 90055, 90056, 90057, 90058, 90059, 90060, 90061, 90062, 90063, 90064, 90065, 90066, 90067, 90068,
  90069, 90070, 90071, 90072, 90073, 90074, 90075, 90076, 90077, 90078, 90079, 90080, 90081, 90082, 90083, 90084, 90086,
  90087, 90088, 90089, 90090, 90091, 90093, 90094, 90095, 90096, 90099, 90101, 90102, 90103, 90134, 90189, 90201, 90202,
  90209, 90210, 90211, 90212, 90213, 90220, 90221, 90222, 90223, 90224, 90230, 90231, 90232, 90233, 90239, 90240, 90241,
  90242, 90245, 90247, 90248, 90249, 90250, 90251, 90254, 90255, 90260, 90261, 90262, 90263, 90264, 90265, 90266, 90267,
  90270, 90272, 90274, 90275, 90277, 90278, 90280, 90290, 90291, 90292, 90293, 90294, 90295, 90296, 90301, 90302, 90303,
  90304, 90305, 90306, 90307, 90308, 90309, 90310, 90311, 90312, 90313, 90397, 90398, 90401, 90402, 90403, 90404, 90405,
  90406, 90407, 90408, 90409, 90410, 90411, 90501, 90502, 90503, 90504, 90505, 90506, 90507, 90508, 90509, 90510, 90601,
  90602, 90603, 90604, 90605, 90606, 90607, 90608, 90609, 90610, 90612, 90620, 90621, 90622, 90623, 90624, 90630, 90631,
  90632, 90633, 90637, 90638, 90639, 90640, 90650, 90651, 90652, 90659, 90660, 90661, 90662, 90670, 90671, 90680, 90701,
  90702, 90703, 90704, 90706, 90707, 90710, 90711, 90712, 90713, 90714, 90715, 90716, 90717, 90720, 90721, 90723, 90731,
  90732, 90733, 90734, 90740, 90742, 90743, 90744, 90745, 90746, 90747, 90748, 90749, 90755, 90801, 90802, 90803, 90804,
  90805, 90806, 90807, 90808, 90809, 90810, 90813, 90814, 90815, 90822, 90831, 90832, 90833, 90834, 90835, 90840, 90842,
  90844, 90845, 90846, 90847, 90848, 90853, 90888, 90895, 90899, 91001, 91003, 91006, 91007, 91008, 91009, 91010, 91011,
  91012, 91016, 91017, 91020, 91021, 91023, 91024, 91025, 91030, 91031, 91040, 91041, 91042, 91043, 91046, 91066, 91077,
  91101, 91102, 91103, 91104, 91105, 91106, 91107, 91108, 91109, 91110, 91114, 91115, 91116, 91117, 91118, 91121, 91123,
  91124, 91125, 91126, 91129, 91131, 91182, 91184, 91185, 91188, 91189, 91191, 91199, 91201, 91202, 91203, 91204, 91205,
  91206, 91207, 91208, 91209, 91210, 91214, 91221, 91222, 91224, 91225, 91226, 91301, 91302, 91303, 91304, 91305, 91306,
  91307, 91308, 91309, 91310, 91311, 91313, 91316, 91321, 91322, 91324, 91325, 91326, 91327, 91328, 91329, 91330, 91331,
  91333, 91334, 91335, 91337, 91340, 91341, 91342, 91343, 91344, 91345, 91346, 91350, 91351, 91352, 91353, 91354, 91355,
  91356, 91357, 91359, 91363, 91364, 91365, 91367, 91371, 91372, 91376, 91380, 91381, 91382, 91383, 91384, 91385, 91386,
  91387, 91388, 91390, 91392, 91393, 91394, 91395, 91396, 91399, 91401, 91402, 91403, 91404, 91405, 91406, 91407, 91408,
  91409, 91410, 91411, 91412, 91413, 91416, 91423, 91426, 91436, 91470, 91482, 91495, 91496, 91497, 91499, 91501, 91502,
  91503, 91504, 91505, 91506, 91507, 91508, 91510, 91521, 91522, 91523, 91526, 91601, 91602, 91603, 91604, 91605, 91606,
  91607, 91608, 91609, 91610, 91611, 91612, 91614, 91615, 91616, 91617, 91618, 91702, 91706, 91711, 91714, 91715, 91716,
  91722, 91723, 91724, 91731, 91732, 91733, 91734, 91735, 91740, 91741, 91744, 91745, 91746, 91747, 91748, 91749, 91750,
  91754, 91755, 91756, 91765, 91766, 91767, 91768, 91769, 91770, 91771, 91772, 91773, 91775, 91776, 91778, 91780, 91788,
  91789, 91790, 91791, 91792, 91793, 91795, 91797, 91799, 91801, 91802, 91803, 91804, 91841, 91896, 91899, 92602, 92603,
  92604, 92605, 92606, 92607, 92609, 92610, 92612, 92614, 92615, 92616, 92617, 92618, 92619, 92620, 92623, 92624, 92625,
  92626, 92627, 92628, 92629, 92630, 92637, 92646, 92647, 92648, 92649, 92650, 92651, 92652, 92653, 92654, 92655, 92656,
  92657, 92658, 92659, 92660, 92661, 92662, 92663, 92672, 92673, 92674, 92675, 92676, 92677, 92678, 92679, 92683, 92684,
  92685, 92688, 92690, 92691, 92692, 92693, 92694, 92697, 92698, 92701, 92702, 92703, 92704, 92705, 92706, 92707, 92708,
  92709, 92710, 92711, 92712, 92725, 92728, 92735, 92780, 92781, 92782, 92799, 92801, 92802, 92803, 92804, 92805, 92806,
  92807, 92808, 92809, 92811, 92812, 92814, 92815, 92816, 92817, 92821, 92822, 92823, 92825, 92831, 92832, 92833, 92834,
  92835, 92836, 92837, 92838, 92840, 92841, 92842, 92843, 92844, 92845, 92846, 92850, 92856, 92857, 92859, 92861, 92862,
  92863, 92864, 92865, 92866, 92867, 92868, 92869, 92870, 92871, 92885, 92886, 92887, 92899, 93510, 93532, 93534, 93535,
  93536, 93539, 93543, 93544, 93550, 93551, 93552, 93553, 93563, 93584, 93586, 93590, 93591, 93599, 94002, 94005, 94010,
  94011, 94013, 94014, 94015, 94016, 94017, 94018, 94019, 94020, 94021, 94022, 94023, 94024, 94025, 94026, 94027, 94028,
  94030, 94035, 94037, 94038, 94039, 94040, 94041, 94042, 94043, 94044, 94060, 94061, 94062, 94063, 94064, 94065, 94066,
  94070, 94074, 94080, 94083, 94085, 94086, 94087, 94088, 94089, 94101, 94102, 94103, 94104, 94105, 94106, 94107, 94108,
  94109, 94110, 94111, 94112, 94114, 94115, 94116, 94117, 94118, 94119, 94120, 94121, 94122, 94123, 94124, 94125, 94126,
  94127, 94128, 94129, 94130, 94131, 94132, 94133, 94134, 94135, 94136, 94137, 94138, 94139, 94140, 94141, 94142, 94143,
  94144, 94145, 94146, 94147, 94150, 94151, 94152, 94153, 94154, 94155, 94156, 94158, 94159, 94160, 94161, 94162, 94163,
  94164, 94171, 94172, 94175, 94177, 94188, 94199, 94301, 94302, 94303, 94304, 94305, 94306, 94309, 94401, 94402, 94403,
  94404, 94497, 94501, 94502, 94505, 94506, 94507, 94509, 94511, 94513, 94514, 94516, 94517, 94518, 94519, 94520, 94521,
  94522, 94523, 94524, 94525, 94526, 94527, 94528, 94529, 94530, 94531, 94536, 94537, 94538, 94539, 94540, 94541, 94542,
  94543, 94544, 94545, 94546, 94547, 94548, 94549, 94550, 94551, 94552, 94553, 94555, 94556, 94557, 94560, 94561, 94563,
  94564, 94565, 94566, 94568, 94569, 94570, 94572, 94575, 94577, 94578, 94579, 94580, 94582, 94583, 94586, 94587, 94588,
  94595, 94596, 94597, 94598, 94601, 94602, 94603, 94604, 94605, 94606, 94607, 94608, 94609, 94610, 94611, 94612, 94613,
  94614, 94615, 94617, 94618, 94619, 94620, 94621, 94622, 94623, 94624, 94625, 94649, 94659, 94660, 94661, 94662, 94666,
  94701, 94702, 94703, 94704, 94705, 94706, 94707, 94708, 94709, 94710, 94712, 94720, 94801, 94802, 94803, 94804, 94805,
  94806, 94807, 94808, 94820, 94850, 94901, 94903, 94904, 94912, 94913, 94914, 94915, 94920, 94922, 94923, 94924, 94925,
  94926, 94927, 94928, 94929, 94930, 94931, 94933, 94937, 94938, 94939, 94940, 94941, 94942, 94945, 94946, 94947, 94948,
  94949, 94950, 94951, 94952, 94953, 94954, 94955, 94956, 94957, 94960, 94963, 94964, 94965, 94966, 94970, 94971, 94972,
  94973, 94974, 94975, 94976, 94977, 94978, 94979, 94998, 94999, 95001, 95002, 95003, 95005, 95006, 95007, 95008, 95009,
  95010, 95011, 95013, 95014, 95015, 95017, 95018, 95019, 95020, 95021, 95026, 95030, 95031, 95032, 95033, 95035, 95036,
  95037, 95038, 95041, 95042, 95044, 95046, 95050, 95051, 95052, 95053, 95054, 95055, 95056, 95060, 95061, 95062, 95063,
  95064, 95065, 95066, 95067, 95070, 95071, 95073, 95076, 95077, 95101, 95103, 95106, 95108, 95109, 95110, 95111, 95112,
  95113, 95115, 95116, 95117, 95118, 95119, 95120, 95121, 95122, 95123, 95124, 95125, 95126, 95127, 95128, 95129, 95130,
  95131, 95132, 95133, 95134, 95135, 95136, 95138, 95139, 95140, 95141, 95148, 95150, 95151, 95152, 95153, 95154, 95155,
  95156, 95157, 95158, 95159, 95160, 95161, 95164, 95170, 95172, 95173, 95190, 95191, 95192, 95193, 95194, 95196, 95401,
  95402, 95403, 95404, 95405, 95406, 95407, 95409, 95412, 95416, 95419, 95421, 95422, 95423, 95424, 95425, 95426, 95430,
  95431, 95433, 95435, 95436, 95439, 95441, 95442, 95443, 95444, 95446, 95448, 95450, 95451, 95452, 95453, 95457, 95458,
  95461, 95462, 95464, 95465, 95467, 95471, 95472, 95473, 95476, 95480, 95485, 95486, 95487, 95492, 95493, 95497
]; // 1138
export default CaliforniaZipCodes;
