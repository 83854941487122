const ArizonaZipCodes = [
  85001, 85002, 85003, 85004, 85005, 85006, 85007, 85008, 85009, 85010, 85011, 85012, 85013, 85014, 85015, 85016, 85017,
  85018, 85019, 85020, 85021, 85022, 85023, 85024, 85025, 85026, 85027, 85028, 85029, 85030, 85031, 85032, 85033, 85034,
  85035, 85036, 85037, 85038, 85039, 85040, 85041, 85042, 85043, 85044, 85045, 85046, 85048, 85050, 85051, 85053, 85054,
  85055, 85060, 85061, 85062, 85063, 85064, 85065, 85066, 85067, 85068, 85069, 85070, 85071, 85072, 85073, 85074, 85075,
  85076, 85077, 85078, 85079, 85080, 85082, 85083, 85085, 85086, 85087, 85096, 85097, 85098, 85099, 85127, 85135, 85142,
  85190, 85201, 85202, 85203, 85204, 85205, 85206, 85207, 85208, 85209, 85210, 85211, 85212, 85213, 85214, 85215, 85216,
  85224, 85225, 85226, 85227, 85233, 85234, 85235, 85236, 85240, 85242, 85243, 85244, 85246, 85248, 85249, 85250, 85251,
  85252, 85253, 85254, 85255, 85256, 85257, 85258, 85259, 85260, 85261, 85262, 85263, 85264, 85266, 85267, 85268, 85269,
  85271, 85274, 85275, 85277, 85280, 85281, 85282, 85283, 85284, 85285, 85286, 85287, 85288, 85289, 85290, 85295, 85296,
  85297, 85298, 85299, 85301, 85302, 85303, 85304, 85305, 85306, 85307, 85308, 85309, 85310, 85311, 85312, 85313, 85318,
  85320, 85321, 85322, 85323, 85324, 85326, 85327, 85329, 85331, 85332, 85333, 85335, 85336, 85337, 85338, 85339, 85340,
  85341, 85342, 85343, 85345, 85347, 85349, 85350, 85351, 85352, 85353, 85354, 85355, 85356, 85358, 85361, 85362, 85363,
  85364, 85365, 85366, 85367, 85369, 85372, 85373, 85374, 85375, 85376, 85377, 85378, 85379, 85380, 85381, 85382, 85383,
  85385, 85387, 85388, 85390, 85392, 85395, 85396, 85501, 85502, 85530, 85531, 85532, 85535, 85536, 85539, 85541, 85542,
  85543, 85544, 85545, 85546, 85547, 85548, 85550, 85551, 85552, 85553, 85554, 85601, 85602, 85603, 85605, 85606, 85607,
  85608, 85609, 85610, 85611, 85613, 85614, 85615, 85616, 85617, 85619, 85620, 85621, 85622, 85624, 85625, 85626, 85627,
  85628, 85629, 85630, 85632, 85633, 85634, 85635, 85636, 85637, 85638, 85639, 85640, 85641, 85643, 85644, 85645, 85646,
  85648, 85650, 85652, 85653, 85654, 85655, 85658, 85662, 85670, 85671, 85701, 85702, 85703, 85704, 85705, 85706, 85707,
  85708, 85709, 85710, 85711, 85712, 85713, 85714, 85715, 85716, 85717, 85718, 85719, 85720, 85721, 85722, 85723, 85724,
  85725, 85726, 85728, 85730, 85731, 85732, 85733, 85734, 85735, 85736, 85737, 85738, 85740, 85741, 85742, 85743, 85744,
  85745, 85746, 85747, 85748, 85749, 85750, 85751, 85752, 85754, 85755, 85756, 85757, 85775, 85777, 85901, 85902, 85911,
  85912, 85923, 85926, 85928, 85929, 85930, 85933, 85934, 85935, 85937, 85939, 85941, 85942, 86025, 86029, 86030, 86031,
  86032, 86033, 86034, 86039, 86042, 86043, 86047, 86054, 86301, 86302, 86303, 86304, 86305, 86312, 86313, 86314, 86315,
  86321, 86322, 86323, 86324, 86325, 86326, 86327, 86329, 86330, 86331, 86332, 86333, 86334, 86335, 86336, 86337, 86338,
  86339, 86340, 86341, 86342, 86343, 86351, 86510, 86520, 86555
]; // 417
export default ArizonaZipCodes;
