const TexasZipCodes = [
  73301, 73344, 75001, 75002, 75006, 75009, 75011, 75013, 75014, 75015, 75016, 75017, 75019, 75023, 75024, 75025, 75026,
  75030, 75034, 75035, 75037, 75038, 75039, 75040, 75041, 75042, 75043, 75044, 75045, 75046, 75047, 75048, 75049, 75050,
  75051, 75052, 75053, 75060, 75061, 75062, 75063, 75064, 75069, 75070, 75071, 75072, 75074, 75075, 75078, 75080, 75081,
  75082, 75083, 75085, 75086, 75088, 75089, 75093, 75094, 75097, 75098, 75099, 75104, 75106, 75115, 75116, 75121, 75123,
  75134, 75137, 75138, 75141, 75146, 75149, 75150, 75159, 75164, 75166, 75172, 75173, 75180, 75181, 75182, 75185, 75187,
  75201, 75202, 75203, 75204, 75205, 75206, 75207, 75208, 75209, 75210, 75211, 75212, 75214, 75215, 75216, 75217, 75218,
  75219, 75220, 75221, 75222, 75223, 75224, 75225, 75226, 75227, 75228, 75229, 75230, 75231, 75232, 75233, 75234, 75235,
  75236, 75237, 75238, 75240, 75241, 75242, 75243, 75244, 75245, 75246, 75247, 75248, 75249, 75250, 75251, 75252, 75253,
  75254, 75258, 75260, 75262, 75263, 75264, 75265, 75266, 75267, 75270, 75275, 75277, 75283, 75284, 75285, 75286, 75301,
  75303, 75310, 75312, 75313, 75315, 75320, 75323, 75326, 75334, 75336, 75339, 75340, 75342, 75343, 75344, 75353, 75354,
  75355, 75356, 75357, 75358, 75359, 75360, 75363, 75364, 75367, 75368, 75370, 75371, 75372, 75373, 75374, 75376, 75378,
  75379, 75380, 75381, 75382, 75386, 75387, 75388, 75389, 75390, 75391, 75392, 75393, 75394, 75395, 75396, 75397, 75398,
  75407, 75409, 75424, 75442, 75454, 75485, 75630, 75657, 75852, 75934, 75939, 75960, 77326, 77332, 77335, 77350, 77351,
  77360, 77399, 77426, 77611, 77626, 77630, 77631, 77632, 77639, 77662, 77670, 77833, 77834, 77835, 77864, 77872, 77880,
  77957, 77961, 77962, 77969, 77970, 77971, 77991, 78615, 78617, 78645, 78652, 78653, 78660, 78669, 78691, 78701, 78702,
  78703, 78704, 78705, 78708, 78709, 78710, 78711, 78712, 78713, 78714, 78715, 78716, 78718, 78719, 78720, 78721, 78722,
  78723, 78724, 78725, 78726, 78727, 78728, 78730, 78731, 78732, 78733, 78734, 78735, 78736, 78738, 78739, 78741, 78742,
  78744, 78745, 78746, 78747, 78748, 78749, 78750, 78751, 78752, 78753, 78754, 78755, 78756, 78757, 78758, 78759, 78760,
  78761, 78762, 78763, 78764, 78765, 78766, 78767, 78768, 78769, 78772, 78773, 78774, 78778, 78779, 78780, 78781, 78783,
  78785, 78786, 78788, 78789, 78798, 78799, 79236
]; // 330
export default TexasZipCodes;
